<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        :edit.sync="edit"
        :view="model && model.value && model.value.slug ? `${frontendUrl}${model.value.slug}` : null"
        class="mb-4"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.value.title"
            :value="model.value.title"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Metadata Key"
      />
      <vue-data
        v-model="model.value.slug"
        :value="model.value.slug"
        :edit="edit"
        class="mb-2"
        label="Slug"
      />
      <SeoSection
        :model.sync="model"
        :edit="edit"
        class="mt-8"
      />
    </template>
    <template #main>
      <LocaleSwitcher
        :edit="edit"
        @switch="getModel"
      />
      <GridLayoutContainer
        :data.sync="model.value.layout"
        :images="model.images"
        :edit-mode="edit"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'
import LocaleSwitcher from '@/components/LocaleSwitcher'

export default {
  name: 'PagesSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard,
    LocaleSwitcher
  },

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      route: {
        apiEndpoint: 'metadata',
        index: 'pages.index'
      }
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },

    content () {
      return this.value && this.value.content ? this.value.content : false
    },

    frontendUrl () {
      return process.env.VUE_APP_FRONTEND_URL
    }
  },

  created () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          locale: this.$route.params.locale,
          withImages: true
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        ...this.model,
        locale: this.$route.params.locale
      })
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
